import * as React from 'react';
import Layout from '../components/layout/layout';
import '../assets/css/main.scss';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import Hero from '../sections/hero/hero';

const NotFoundPage = () => {
  const { language } = useI18next();
  return (
    <Layout pageTitle={ language === 'nl' ? 'Pagina niet gevonden' : 'Page not found' }>
      <>
        <Hero size={'small'}/>
        {
          language === 'nl'&&
          <>
            <header>
              <h1>404<span>Pagina niet gevonden</span></h1>
            </header>
            <p>De pagina die je zocht kunnen we niet vinden.</p>
          </>
        }
        {
          language === 'en'&&
          <>
            <header>
              <h1>404<span>Page not found</span></h1>
            </header>
            <p>We can't find the page you were looking for.</p>
          </>
        }
      </>
    </Layout>
  );
};

export default NotFoundPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
