module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cb1da26dd0ce6e8bf7eb97635288056c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"en","languages":["en"],"siteUrl":"https://www.urbanjungle.care","i18nextOptions":{"keySeparator":".","nsSeparator":":","defaultNs":"translation","defaultLanguage":"en","languages":["en"],"returnEmptyString":false,"returnNull":false,"wait":true,"detection":{"order":["querystring","localStorage","cookie","path","navigator"],"lookupQuerystring":"lang","lookupCookie":"CC","lookupLocalStorage":"lang","lookupFromPathIndex":0,"caches":["localStorage"],"checkWhitelist":true},"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true}},
    }]
