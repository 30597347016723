import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import './language-selector.scss';
// @ts-ignore
import LanguageEN from '../../assets/images/en.svg';
// @ts-ignore
import LanguageNL from '../../assets/images/nl.svg';

export const LanguageSelector = () => {
  const { languages, language, originalPath } = useI18next();
  useEffect(() => {
  }, languages);

  return (
    <ul className="languages">
      { languages.map((lng: string) => (
        <li key={ lng }>
          <Link to={ originalPath } language={ lng }
                className={ language === lng ? 'language language__selected' : 'language' }>
            {
              lng === 'nl' && <LanguageNL/> ||
              lng === 'en' && <LanguageEN/>
            }
          </Link>
        </li>
      )) }
    </ul>
  );
};
