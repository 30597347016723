import * as React from 'react';
import Layout from '../components/layout/layout';
import '../assets/css/main.scss';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import QandASection from '../sections/q&a/q&a';
import Hero from '../sections/hero/hero';
import Discover from '../sections/discover/discover';

const IndexPage = () => {
  const { language } = useI18next();
  return (
    <Layout pageTitle="Keep your urban jungle healty and thriving">
      <Discover/>
      <Hero size={ 'normal' }/>
      <QandASection/>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
