import React from 'react';
import '../../assets/icons/font-awesome';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// @ts-ignore
import FollowUs from '../../assets/images/follow-us-on-social.svg';
import './cta.scss';

type CallToActionArgs = {
}
export default function CallToAction({}: CallToActionArgs) {
  return (
    <div className="socials">
      <div className="follow-us">
        <FollowUs/>
      </div>
      <ul>
        <li>
          <a className="icon" href="https://www.instagram.com/urbanjungle.care" target={ '_blank' }
             rel={ 'noreferrer' }>
            <FontAwesomeIcon icon={ ['fab', 'instagram'] }/>
          </a>
        </li>
        <li>
          <a className="icon" href="https://www.tiktok.com/@urbanjungle.care" target={ '_blank' }
             rel={ 'noreferrer' }>
            <FontAwesomeIcon icon={ ['fab', 'tiktok'] }/>
          </a>
        </li>
        <li>
          <a className="icon" href="https://facebook.com/urbanjungle.care" target={ '_blank' } rel={ 'noreferrer' }>
            <FontAwesomeIcon icon={ ['fab', 'facebook'] }/>
          </a>
        </li>
        <li>
          <a className="icon" href="https://www.linkedin.com/in/urbanjungle-care" target={ '_blank' }
             rel={ 'noreferrer' }>
            <FontAwesomeIcon icon={ ['fab', 'linkedin'] }/>
          </a>
        </li>
      </ul>
    </div>
  );
}

