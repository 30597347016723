import React, { useState } from 'react';
import './qanda.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface QandaProps {
  question: string;
  children: React.ReactChild;
}

const QandaComponent: React.FC<QandaProps> = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="qna" onClick={ toggleAnswer }>
      <div className="question_container">
        <span className="handle">
          { isOpen ? <FontAwesomeIcon size="lg" icon={ faChevronCircleUp as IconProp }/> : <FontAwesomeIcon size="lg" icon={ faChevronCircleDown as IconProp }/> }
        </span>
        <span className="question">{ question }</span>
      </div>
      { isOpen && <div className="answer">{ children }</div> }
    </div>
  );
};

export default QandaComponent;
