import React from 'react';
import '../../assets/icons/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// @ts-ignore
import './footer.scss';
// @ts-ignore
import ButtonOverlay from './../../assets/images/button-overlay.svg';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer_container">
      <div className="footer_wrapper">
        <div className="footer">
          <h3>
            Let's building strong roots together, join our Slack now!
          </h3>

          <div className="button_overlay">
            <ButtonOverlay/>
          </div>

          <a href="https://urbanjunglecare.slack.com" target={ '_blank' } rel={ 'noreferrer' }>
            <span className="icon">
                  <FontAwesomeIcon icon={ ['fab', 'slack-hash'] }/>
            </span>
            <span className="label">Join our Slack</span>
          </a>

        </div>
        <div className="footer_menu">
          <ul className="copyright">
            <li>
              © { new Date().getFullYear() } by <a href="https://www.koktail.nl">koktail.nl</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

