import React from 'react';
import './discover.scss';
import { StaticImage } from 'gatsby-plugin-image';

export default function QandA() {
  return (
      <div className="discover_container">
        <div className="discover">
          <div className="discover-text">
            <h1>Your urban jungle health coach.</h1>
            <p>
              With the help of BLE sensor technology UrbanJungle.care reads vital information from your plants and  analyze this data to offer you tailored care tasks. It's like having a personal plant advisor at your
              fingertips!
            </p>
          </div>
          <div className="discover-image">
            <StaticImage
              placeholder="blurred"
              quality={ 100 }
              src="../../assets/images/discover-app.png"
              alt="QandA the app"
            />
          </div>
        </div>
      </div>
  );
}
