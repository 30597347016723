import { Link } from 'gatsby';
import * as React from 'react';
import './header.scss';
import { LanguageSelector } from '../language-selector/language-selector';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
// @ts-ignore
import Logo from './../../assets/images/urban-jungle-care-logo.svg';
import CallToAction from '../cta/cta';

export const Header = () => {
  const { title } = useSiteMetadata();
  return <header className="header_container">
    <div className="header">
      <Link className="logo" to="/" title={ `link to ${ title }` }>
        <Logo/>
      </Link>
      <CallToAction/>
      <LanguageSelector/>
    </div>
  </header>;
};
