import {library} from '@fortawesome/fontawesome-svg-core';
import {faClose, faEnvelope, faQuestion, faStar} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faInstagram,
  faFacebook,
  faLinkedin,
  faSlackHash,
  faTiktok,
  faTwitter,
  faNodeJs,
  faSymfony,
  faReact,
  faReacteurope,
  faAngular,
  faPhp,
  faAndroid,
  faFigma,
  faApple,
  faNode,
} from '@fortawesome/free-brands-svg-icons';

library.add([
  faEnvelope,
  faLinkedin,
  faFacebook,
  faInstagram,
  faGithub,
  faSlackHash,
  faTwitter,
  faTiktok,
  faNodeJs,
  faNode,
  faSymfony,
  faReact,
  faReacteurope,
  faAngular,
  faPhp,
  faAndroid,
  faApple,
  faQuestion,
  faFigma,
  faClose,
  faStar,
] as any);
